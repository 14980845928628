import React from "react";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import logo from "../../../assets/images/header/logo.png";
import { Link } from "react-router-dom";

export const SidebarFooter = () => {
  return (
    <div className="min-h-10 flex items-center justify-between absolute bottom-0 border-t border-header-border w-full px-8">
      <button className="inline-flex items-center space-x-2 text-side-footer-text">
        <img
          alt="logo"
          height={40}
          width={40}
          src="https://static.wixstatic.com/media/eb285e_f713b72b5bde4d4ba7a80fb661f9f0cb~mv2.png/v1/fill/w_186,h_186,al_c,lg_1,q_85,enc_auto/eb285e_f713b72b5bde4d4ba7a80fb661f9f0cb~mv2.png"
        />
      </button>

      <button className="inline-flex items-center space-x-1 text-side-footer-text">
        <QuestionMarkCircledIcon className="w-4 h-4 text-" />
        <Link to="/help">help</Link>
      </button>
    </div>
  );
};
