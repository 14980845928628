import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import ReactPlayer from "react-player";
import Play from "../../assets/images/play.svg";
import Pause from "../../assets/images/pause.svg";
import { formatTime } from "../../utils";

interface CustomAudioPlayerProps {
  url: string;
  duration:any;
  setDuration:any
}

export const CustomAudioPlayer: React.FC<CustomAudioPlayerProps> = ({
  url,
  duration,
  setDuration
}) => {
  const [playing, setPlaying] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(0.8);
 
  const [currentTime, setCurrentTime] = useState<number>(0);
  const playerRef = useRef<ReactPlayer>(null);
  const waveSurferRef = useRef<WaveSurfer | null>(null);
  const waveformContainerRef = useRef<HTMLDivElement | null>(null);


  const handlePlayPause = () => {
    setPlaying(!playing);
    if (waveSurferRef.current) {
      if (playing) {
        waveSurferRef.current.pause();
      } else {
        waveSurferRef.current.play();
      }
    }
  };

  return (
    <div className="w-full">
      <div className="w-full flex justify-start space-x-4 items-center">
        <button onClick={handlePlayPause} className=" text-white rounded">
          {playing ? (
            <img src={Pause} width={50} height={50} alt="pause" />
          ) : (
            <img src={Play} width={50} height={50} alt="play" />
          )}
        </button>

        <div className="text-center w-24 text-white text-nowrap">
          <span>{formatTime(currentTime)}</span> /{" "}
          <span>{formatTime(duration)}</span>
        </div>
       
        <div className="relative w-full">
          <div ref={waveformContainerRef} className="w-full"></div>
        </div>
      </div>

      <ReactPlayer
        ref={playerRef}
        url={url}
        playing={playing}
        volume={volume}
        onDuration={(duration) => setDuration(duration)}
        onProgress={({ playedSeconds }) => setCurrentTime(playedSeconds)}
        height="0px"
        width="0px"
        style={{ display: "none" }}
      />
    </div>
  );
};
