
import React, { useEffect, useState } from "react";
import {
  TextInput,
  NormalSwitch,
  ScrollArea,
  DefaultSelect,
} from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  optimisticOptions,
  putRequest,
  queries,
} from "../../react-query";
import { useStoreState } from "../../store/easy-peasy/hooks";

// Define the structure of the form data
interface FormData {
  name: string;
  email: string;
  access_level: string; // Access level as a string
  status: boolean;
  orgs_id: string;
  phone: string;
}

export const UpdateUserInvitation: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useStoreState((store) => store.user); // Get user from the store

  // Fetch invitation data based on user ID
  const { data: invitation = {} } = useQuery(
    [queries.invitations, id],
    () => getRequest(`/users/?user_id=${id}`),
    { enabled: !!id } // Only run if id is available
  );

  // Initialize form data state
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    access_level: "",
    status: true,
    orgs_id: user.org_id,
    phone: "",
  });

  // Handle form data changes
  const onChangeHandler = (
    value: string | boolean | number,
    name: keyof FormData
  ) => {
    // Convert access_level to string
    if (name === "access_level") {
      setFormData({ ...formData, [name]: Number(value).toString() });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Mutation for updating the invitation
  const { mutate: updateInvitation } = useMutation(
    putRequest,
    optimisticOptions(queries.invitations)
  );

  // Handle form submission
  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent default form submission
    const { name, email, access_level, status, phone } = formData;

    // Update the invitation using the mutation
    updateInvitation({
      endPoint: `/users/`,
      payload: {
        user_id: id,
        name,
        phone,
        access_level: Number(access_level),
        status: status ? 1 : 0,
        org_id: user.org_id,
      },
    });

    navigate(-1); // Navigate back after submission
  };

  // Set default role based on user's access level
  const defaultRole = user.access_level >= 1 ? "Admin" : "Agent";

  // Effect for loading invitation data
  useEffect(() => {
    if (invitation) {
      const { name, email, access_level, status, phone } = invitation;
      setFormData({
        name,
        email,
        access_level: access_level || defaultRole, // Use default role if none provided
        phone,
        status: status === 1,
        orgs_id: user.org_id,
      });
    }
  }, [invitation, user.org_id, defaultRole]);

  // Effect to set default access level if not provided
  useEffect(() => {
    if (!formData.access_level) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        access_level: defaultRole,
      }));
    }
  }, [formData.access_level, defaultRole]);

  return (
    <div className="w-full h-full p-4">
      <form
        onSubmit={submitHandler}
        className="w-[65%] mx-auto h-full py-4 space-y-8"
      >
        {/* Header with Status Switch */}
        <div className="rounded-lg border-header-border bg-primary py-2 px-4 w-full flex justify-between items-center">
          <span className="text-perl text-base font-perl">Update</span>
          <div className="flex space-x-6 text-white">
            <NormalSwitch
              title="Status"
              checked={formData.status}
              onCheckedChange={(value: boolean) =>
                onChangeHandler(value, "status")
              }
            />
          </div>
        </div>

        {/* Form Fields */}
        <div className="w-full flex justify-between space-x-4 text-white">
          <div className="w-full rounded-lg border border-header-border bg-primary p-4">
            <ScrollArea className="h-[calc(100vh-310px)]">
              <div className="space-y-2 grid grid-cols-1 gap-x-5 gap-y-4 px-4">
                <TextInput
                  type="text"
                  placeholder="Enter name"
                  value={formData.name}
                  onChange={(e) => onChangeHandler(e.target.value, "name")}
                  required
                  theme="white"
                >
                  <label htmlFor="name">Name</label>
                </TextInput>
                <TextInput
                  type="email"
                  placeholder="Enter email"
                  value={formData.email}
                  onChange={(e) => onChangeHandler(e.target.value, "email")}
                  required
                  theme="white"
                  disabled={true} // Email is not editable
                >
                  <label htmlFor="email">Email</label>
                </TextInput>
                {user.access_level > 0 && (
                  <DefaultSelect
                    theme="white"
                    data={[
                      { label: "Agent", value: "0" },
                      { label: "Admin", value: "1" },
                    ]}
                    value={formData.access_level || defaultRole} // Set default role based on user's access level
                    onValueChange={(value: number) =>
                      onChangeHandler(value, "access_level")
                    }
                    placeholder="Select"
                    keyField="value"
                    valueField="label"
                  >
                    <label htmlFor="access_level">Role</label>
                  </DefaultSelect>
                )}
                <TextInput
                  type="tel"
                  placeholder="Enter phone number"
                  value={formData.phone}
                  onChange={(e) => onChangeHandler(e.target.value, "phone")}
                  required
                  theme="white"
                >
                  <label htmlFor="phone">Phone</label>
                </TextInput>
              </div>
            </ScrollArea>
            {/* Submit Button */}
            <div className="flex justify-end">
              <button type="submit" className="px-4 py-1.5 bg-green rounded-lg">
                Update
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
