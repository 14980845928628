import React from "react";
import {
  HamburgerMenuIcon,
  ChevronDownIcon,
  AvatarIcon,
  GearIcon,
  ArrowRightIcon,
} from "@radix-ui/react-icons";

import { DropdownMenu } from "../../components";
import { useStoreActions, useStoreState } from "../../store/easy-peasy/hooks";
import { useNavigate } from "react-router-dom";

// Define prop types for better type safety
interface MainHeaderProps {
  onToggle: () => void;
}

export const MainHeader: React.FC<MainHeaderProps> = ({ onToggle }) => {
  const navigate = useNavigate();
  const user = useStoreState((store) => store.user);
  const logout = useStoreActions((store) => store.logout);

  // Define profile menu items
  const profileMenuItems = [
    {
      id: "profile",
      name: "Profile",
      path: "/profile",
      icon: <AvatarIcon className="text-accent" />,
    },
    {
      id: "settings",
      name: "Settings",
      path: "/settings",
      icon: <GearIcon className="text-accent" />,
    },
    {
      id: "logout",
      name: "Logout",
      icon: <ArrowRightIcon className="text-accent" />,
      action: () => {
        logout();
        navigate("/auth");
      },
    },
  ];

  // Helper function to get user initials
  const getUserInitials = (name: string) => name.substring(0, 2).toUpperCase();

  return (
    <header className="w-full flex items-center justify-between px-8 h-16 bg-primary border-b border-header-border shadow-lg">
      <div className="flex items-center space-x-4 text-text">
        <button
          onClick={onToggle}
          className="active:rounded-full p-2 bg-transparent active:bg-secondary active:opacity-80"
          aria-label="Toggle menu"
        >
          <HamburgerMenuIcon className="w-6 h-6" />
        </button>
        <h1 className="text-logo font-bold text-3xl font-custom">
          Tring - Admin
        </h1>
      </div>

      {/* User profile dropdown */}
      <div className="flex items-center space-x-4 text-text">
        <DropdownMenu
          innerStyle="min-w-[280px] flex flex-col rounded-md bg-secondary shadow-xl z-100 m-2 py-2"
          arrowStyle="fill-secondary"
          isArrowShow={false}
          data={profileMenuItems}
        >
          <div className="flex items-center space-x-1">
            <div className="bg-user-icon rounded-full w-10 h-10 grid place-content-center">
              <p className="text-text">{getUserInitials(user.name)}</p>
            </div>
            <ChevronDownIcon className="w-5 h-5 text-text" />
          </div>
        </DropdownMenu>
      </div>
    </header>
  );
};
