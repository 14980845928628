import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ScrollArea, List, ToggleGroup } from "../../components";
import { getRequest, queries } from "../../react-query";
import { useQuery } from "react-query";
import { useStoreState } from "../../store/easy-peasy/hooks";

interface Plan {
  price: string;
  units: string;
}

interface Employee {
  title: string;
  description: string;
  tasks: string[];
  status: string;
  template_id: number;
  category: string;
  available_in: string[];
  plans: {
    [key: string]: Plan;
  };
  channel: string;
  img_path: string;
}

interface APIResponse {
  categories: string[];
  virtual_employees: Employee[];
}

const tabs = [
  {
    id: "1",
    name: "Active",
    value: 1,
  },
  {
    id: "2",
    name: "Draft",
    value: 2,
  },
];

export const VirtualStaff = () => {
  const navigate = useNavigate();
  const user = useStoreState((store) => store.user);
  const [input, setInput] = useState({
    tab: 1,
  });

  const { data, isLoading, isError } = useQuery<APIResponse>(
    [queries.employeelist, input.tab],
    () => getRequest(`agents/hiring-list?status=${input.tab}`),
    {
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );

  const employeelist = data?.virtual_employees || [];

  const columns = useMemo(
    () => [
      {
        Header: () => <span className="text-white">Title</span>,
        accessor: "title",
      },
      {
        Header: () => <span className="text-white">Description</span>,
        accessor: "description",
      },
      {
        Header: () => <span className="text-white">Category</span>,
        accessor: "category",
      },
    ],
    []
  );

  const handleRowClick = (data: Employee) => {
    // Navigate to a detailed view or open a modal with more information
    // For now, we'll just log the data
    console.log(data);
  };

  if (isLoading)
    return <div className="text-center text-white">Loading...</div>;
  if (isError)
    return <div className="text-center text-white">Error loading data</div>;

  return (
    <div className="w-full h-full p-10 bg-primary text-perl">
      <List
        columns={columns}
        data={employeelist}
        activeTab={input.tab}
        onRowClick={handleRowClick}
      >
        <div className="space-y-2">
          <h1 className="text-xl font-black">Virtual Staff</h1>
          <div className="flex justify-between items-center">
            <ToggleGroup
              innerClass="w-full text-disabled flex space-x-6"
              outerClass="pb-1 text-sm data-[state=on]:text-white data-[state=on]:border-b-2 data-[state=on]:border-header-text"
              data={tabs}
              defaultValue={input.tab}
              onValueChange={(value: number) =>
                setInput({ ...input, tab: value })
              }
            />

            {user.access_level > 0 && (
              <button
                onClick={() => navigate("/agents/create")}
                className="bg-accent text-primary font-semibold px-6 py-2 rounded-full border-2 border-perl hover:bg-opacity-90 transition-colors duration-200 whitespace-nowrap"
              >
                Hiring Center
              </button>
            )}
          </div>
        </div>
      </List>
    </div>
  );
};
