import { useTable } from "react-table";

export function TranscriptionsDataGrid({ columns,  data }: any) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <div className="shadow overflow-hidden border-b border-t border-header-border">
      <table
        {...getTableProps()}
        className="min-w-full divide-y divide-header-border"
      >
        <tbody
          {...getTableBodyProps()}
          className="relative rounded bg-transparent border border-header-border"
        >
          {rows.map((row: any, i: any) => {
            prepareRow(row);
            return (
              <tr
                className="cursor-pointer hover:bg-[#424244]"
                {...row.getRowProps()}
                key={i}
              >
                {row.cells.map((cell: any, index: number) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="py-3 whitespace-nowrap"
                      role="cell"
                      key={index}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
