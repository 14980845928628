import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { List, ScrollArea, ToggleGroup } from "../../components";
import { useInfiniteQuery } from "react-query";
import { getRequest, queries } from "../../react-query";
import axios from "axios";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useStoreState } from "../../store/easy-peasy/hooks";
import InboundIcon from "../../assets/images/inbound.png";
import OutboundIcon from "../../assets/images/outbound.png";
import { useQuery } from "react-query";

const toTitleCase = (str: string) => {
  return str
    ?.toLowerCase() // Convert to lowercase
    .replace(/_/g, " ") // Replace hyphens with spaces
    .replace(/\b\w/g, (char: string) => char.toUpperCase()); // Capitalize each word
};

const tabs = [
  {
    id: "1",
    name: "Active",
    value: 1,
  },
  {
    id: "2",
    name: "Draft",
    value: 2,
  },
  {
    id: "3",
    name: "Deleted",
    value: -1,
  },
];

// Add this function to fetch org names
const fetchOrgNames = async () => {
  const response = await getRequest("/organisations/list/");
  return response.reduce(
    (acc: Record<string, string>, org: { id: string; name: string }) => {
      acc[org.id] = org.name;
      return acc;
    },
    {}
  );
};

export const CallsList = () => {
  const navigate = useNavigate();
  const user = useStoreState((store) => store.user);
  const [input, setInput] = useState({
    tab: 1,
  });
  const { data: orgNames } = useQuery("orgNames", fetchOrgNames);

  const loadMoreRef = useRef(null);

  const fetchConversations = async ({ pageParam }: { pageParam: number }) => {
    const response = getRequest(
      `/conversations/list?org_id=${user.org_id}&status=${
        input.tab === 2 ? 0 : input.tab
      }&page=${pageParam ?? 1}&size=20`
    );
    return response;
  };

  const { data, error, fetchNextPage, hasNextPage, isFetching, isLoading } =
    useInfiniteQuery({
      queryKey: ["calls"],
      queryFn: ({ pageParam }) => fetchConversations({ pageParam }),
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length ? allPages.length + 1 : undefined;
      },
    });

  const columns = useMemo(
    () => [
      {
        Header: () => <span className="text-white">Organization</span>,
        id: "organization",
        accessor: "org_id",
        Cell: ({ value: org_id }: { value: string }) => (
          <span className="text-x text-perl">
            {orgNames?.[org_id] || "Unknown Organization"}
          </span>
        ),
      },
      {
        Header: () => <span className="text-white"></span>,
        id: "call_type",
        accessor: "call_type",
        //@ts-ignore
        Cell: ({ value }) => {
          if (value === 0) {
            return <img src={InboundIcon} width={16} height={16} alt="In" />;
          } else {
            return <img src={OutboundIcon} width={16} height={16} alt="Out" />;
          }
        },
      },
      {
        Header: () => <span className="text-white">Assistant</span>,
        id: "assistant",
        accessor: (row: any) => ({
          agentName: row.agent_name,
          callTime: row.created_at,
        }),
        //@ts-ignore
        Cell: ({ value }) => {
          const { agentName, callTime } = value;
          return (
            <div className="flex flex-col">
              <span className="text-x font-medium text-perl">
                {agentName || "No Agent"}
              </span>
              <span className="text-sm text-gray-400">
                {callTime
                  ? format(new Date(callTime), "MMM dd, yyyy hh:mm a")
                  : ""}
              </span>
            </div>
          );
        },
      },
      {
        Header: () => <span className="text-white">Called</span>,
        id: "called",
        accessor: (row: any) => ({
          name: row.customer_name,
          number: row.call_type === 0 ? row.from_number : row.to_number,
        }),
        //@ts-ignore
        Cell: ({ value }) => {
          const { name, number } = value;
          return (
            <div className="flex flex-col">
              <span className="text-x font-medium text-perl">
                {name || "---"}
              </span>
              <span className="text-sm text-gray-400">
                {number ? `(${number})` : ""}
              </span>
            </div>
          );
        },
      },
      {
        Header: () => <span className="text-white">Call Status</span>,
        id: "status",
        accessor: "status",
        //@ts-ignore
        Cell: ({ value }) => {
          const formattedStatus = toTitleCase(value);
          return <span className="text-x text-perl">{formattedStatus}</span>;
        },
      },
      {
        Header: () => <span className="text-white">Duration</span>,
        id: "duration",
        accessor: "duration",
        //@ts-ignore
        Cell: ({ value }) => {
          const totalSeconds = value;
          const minutes = Math.floor(totalSeconds / 60);
          const seconds = totalSeconds % 60;
          let formattedDuration;
          if (seconds <= 0) {
            formattedDuration = `No recording`;
          } else {
            formattedDuration = `${minutes}:${seconds}`;
          }
          // const formattedDuration = (value / 60).toFixed(2) + " mins";
          return <span className="text-x text-perl">{formattedDuration}</span>;
        },
      },
    ],
    [orgNames]
  );
  const handleRowClick = (data: any) => {
    navigate(`/calls/${data.id}/transcriptions`, {
      state: {
        created_at: data.created_at,
        status: toTitleCase(data.status),
        name: data.agent_name,
        phone_number: data.call_type === 0 ? data.from_number : data.to_number,
        recording_url: data.recording_url,
      },
    });
  };

  const loadMore = useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMore();
        }
      },
      { threshold: 1.0 }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [loadMore]);

  return (
    <div className="w-full h-full p-10 bg-primary text-white">
      <List
        columns={columns}
        data={data?.pages.flatMap((page) => page) ?? []}
        activeTab={input["tab"]}
        onRowClick={handleRowClick}
        loadMoreRef={loadMoreRef}
      >
        <div className="space-y-2">
          <h1 className="text-xl font-black text-perl">Calls</h1>{" "}
          <div className="flex   text-perl  justify-between items-center">
          </div>
        </div>
      </List>
    </div>
  );
};
