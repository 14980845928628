import { useEffect, useState } from "react";
import { validateField, validateForm } from "../../utils";
import { TextInput } from "..";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { optimisticOptions, postRequest, queries } from "../../react-query";
import { useStoreActions } from "../../store/easy-peasy/hooks";
import { toast } from "react-toastify";

const validationRules = {
  email: [
    { required: true, message: "Please enter your email" },
    { pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: "Invalid email format" },
  ],
  password: [
    { required: true },
    {
      pattern: /^(?=.*[a-z]).{6,}$/,
      message:
        "Password must be at least 6 characters and include at least one uppercase letter, one lowercase letter, and one number",
    },
  ],
};

export function ForgotPasswordForm({ setAuthPage }: any) {
  const { data: signinResponse, mutate: signin } = useMutation(postRequest);
  const [isLoading, setIsLoading] = useState(false);

  let addUser = useStoreActions((store) => store.addUser);

  const naviagte = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [isHide, setIsHide] = useState(true);

  const [errors, setErrors] = useState<Record<string, string>>({});

  const onChangeHandler = (value: string | boolean, name: string) => {
    setFormData({ ...formData, [name]: value });
    // Validate field on change
    const fieldErrors = validateField(name, value, validationRules);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: fieldErrors.length > 0 ? fieldErrors[0] : "",
    }));
  };

  const { mutate: resetPassword } = useMutation(
    postRequest,
    optimisticOptions(queries.user)
  );

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    try {
      await resetPassword({
        endPoint: `/users/forgot-password?email=${formData.email}`,
      });
      toast.success("Password reset link sent to your email");
    } catch (error) {
      toast.error("Error sending password reset link");
      setErrors({});
    }
  };

  useEffect(() => {
    setAuthPage("ResetPasswordForm");
  }, []);

  return (
    <form className="h-w-full w-full space-y-24" onSubmit={onSubmitHandler}>
      <div className="space-y-5">
        <TextInput
          type="email"
          value={formData.email}
          onChange={(e) => onChangeHandler(e.target.value, "email")}
          required
          theme="black"
          error={errors.email}
        >
          <label className="" htmlFor="Email">
            Email
          </label>
        </TextInput>
      </div>
      <button
        className="w-full rounded-full bg-secondary text-white py-2"
        type="submit"
      >
        {isLoading ? "Please Wait" : "Forgot Password"}
      </button>
    </form>
  );
}
