import React, { useEffect, useState } from "react";
import { NormalSwitch, ScrollArea, TextInput } from "../../components";
import { TextArea } from "../../components/input/TextArea";
import { TrashIcon } from "@radix-ui/react-icons";
import { useStoreState } from "../../store/easy-peasy/hooks";
import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  putRequest,
  queries,
} from "../../react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const Profile: React.FC = () => {
  let user = useStoreState((store) => store.user);
  const navigate = useNavigate();
  const [alert, setAlert] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    password: "",
    confirmPassword: "",
    status: true,
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    contactNumber: "",
    password: "",
    confirmPassword: "",
  });

  const onChangeHandler = (value: string, field: keyof typeof formData) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const { data: userInfo = {} } = useQuery(
    [queries.user, user.user_id],
    () => getRequest(`/users/?user_id=${user.user_id}`),
    {
      enabled: !!user.user_id,
    }
  );

  const { mutate: updateUser } = useMutation(
    putRequest,
    optimisticOptions(queries.user)
  );

  const { mutate: resetPassword } = useMutation(
    postRequest,
    optimisticOptions(queries.user)
  );

  const handleResetPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    resetPassword({
      endPoint: `/users/forgot-password?email=${user.email}`,
    });
    toast.success("Password reset link sent to your email");
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Reset errors
    setErrors({
      name: "",
      email: "",
      contactNumber: "",
      password: "",
      confirmPassword: "",
    });

    updateUser({
      endPoint: `/users/`,
      payload: {
        name: formData.name,
        phone: formData.contactNumber,
      },
    });

    toast.success("Profile updated successfully");

    setAlert("Profile updated successfully");
  };

  useEffect(() => {
    if (userInfo) {
      setFormData({
        ...formData,
        name: userInfo.name,
        email: userInfo.email,
        contactNumber: userInfo.phone,
      });
    }
  }, [userInfo]);

  return (
    <div className="w-full h-full p-4">
      <form
        onSubmit={submitHandler}
        className="w-[60%] mx-auto h-full py-4 space-y-8"
      >
        <div className="text-center w-full px-4 ">
          <h1 className="text-white text-2xl mb-4  pt-10">Your Profile</h1>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 "></div>
        </div>

        <div className="w-full flex justify-center space-y-4 text-white">
          <div className="w-full rounded-lg border border-header-border bg-primary p-4">
            <ScrollArea className="h-[calc(100vh-340px)]">
              <div className="space-y-4 px-4">
                <TextInput
                  type="text"
                  placeholder="Name"
                  value={formData.name}
                  onChange={(e) => onChangeHandler(e.target.value, "name")}
                  required
                  theme="white"
                  error={errors.name}
                >
                  <label htmlFor="name">Name</label>
                </TextInput>

                <TextInput
                  type="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={(e) => onChangeHandler(e.target.value, "email")}
                  required
                  theme="white"
                  error={errors.email}
                  disabled
                >
                  <label htmlFor="email">Email</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Contact Number"
                  value={formData.contactNumber}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "contactNumber")
                  }
                  required
                  theme="white"
                  error={errors.contactNumber}
                >
                  <label htmlFor="contactNumber">Contact Number</label>
                </TextInput>
              </div>
            </ScrollArea>
            <div className="flex justify-end mt-4 space-x-2">
              <button type="submit" className="px-4 py-1.5 bg-green rounded-lg">
                Update Profile
              </button>
              <button
                type="button"
                onClick={handleResetPassword}
                className="px-4 py-1.5 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-opacity-50 transition ease-in-out duration-150 shadow-md"
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
