import React, { useEffect, useMemo, useState } from "react";
import { List, ScrollArea, ToggleGroup } from "../../components";
import { useQuery, useQueryClient } from "react-query";
import { getRequest, queries } from "../../react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { useStoreState } from "../../store/easy-peasy/hooks";

const tabs = [
  { id: "1", name: "Active", value: "active" },
  { id: "2", name: "Draft", value: "draft" },
  { id: "3", name: "Deleted", value: "deleted" },
];

export const ListAgentTemplate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useStoreState((store) => store.user);
  const [tab, setTab] = useState("active");

  const {
    data: agents = [],
    isLoading,
    error,
    refetch,
  } = useQuery(
    [queries.agentTemplates, tab, user.org_id], // Add user.org_id to the dependency array
    async () => {

      const response = await getRequest(
        `/v2/agent_templates/list?status=${tab}&org_id=${user.org_id}` // Add org_id to the API endpoint
      );
      return response;
    },
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
    }
  );

  useEffect(() => {
    refetch();
  }, [tab, refetch]);

  useEffect(() => {
    queryClient.invalidateQueries([queries.agentTemplates, tab]);
  }, [tab, queryClient]);

  useEffect(() => {
    const handleUnhandledRejection = (event: PromiseRejectionEvent) => {
      console.error("Unhandled promise rejection:", event.reason);
      event.preventDefault();
    };

    window.addEventListener("unhandledrejection", handleUnhandledRejection);

    return () => {
      window.removeEventListener(
        "unhandledrejection",
        handleUnhandledRejection
      );
    };
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: () => <span className="text-white">Name</span>,
        accessor: "name",
      },
      {
        Header: () => <span className="text-white">Category</span>,
        accessor: "category",
      },
      {
        Header: () => <span className="text-white">Channel</span>,
        accessor: "channel",
      },
      {
        Header: () => <span className="text-white">Created</span>,
        accessor: "created_at",
        Cell: ({ value }: { value: string }) =>
          format(new Date(value), "MMM dd, yyyy hh:mm a"),
      },
    ],
    []
  );

  const handleRowClick = (data: any) => {
    navigate(`/agent-templates/update/${data.id}`, { state: { data } });
  };


  const getActiveTabIndex = (tabValue: string): number => {
    const index = tabs.findIndex((t) => t.value === tabValue);
    return index !== -1 ? index + 1 : 1; // Return 1 as default if not found
  };

  return (
    <div className="w-full h-full p-10 bg-primary text-white">
      {isLoading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>
          Error loading agents:{" "}
          {error instanceof Error ? error.message : "Unknown error"}
        </p>
      ) : (
        <>
          <List
            columns={columns}
            data={agents}
            activeTab={getActiveTabIndex(tab)}
            onRowClick={handleRowClick}
          >
            <div className="space-y-2">
              <h1 className="text-xl font-black">Agent Templates</h1>
              <div className="flex text-disabled justify-between items-center">
                <ToggleGroup
                  innerClass="w-full text-white flex space-x-6"
                  outerClass="pb-1 text-sm data-[state=on]:text-white data-[state=on]:text-side-footer-text data-[state=on]:border-b-2 data-[state=on]:border-header-text"
                  data={tabs}
                  value={tab}
                  onValueChange={(value: string) => setTab(value)}
                />
                {user.access_level > 0 && (
                  <button
                    onClick={() => navigate("/agent-templates/create")}
                    className="bg-accent text-primary font-semibold px-6 py-2 rounded-full border-2 border-perl hover:bg-opacity-90 transition-colors duration-200 whitespace-nowrap"
                  >
                    Create Agent Template
                  </button>
                )}
              </div>
            </div>
          </List>
        </>
      )}
    </div>
  );
};
