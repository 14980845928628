import axios from "axios";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ScrollArea, TextInput } from "../../components";
import { toast } from "react-toastify";

export const ResetPassword: React.FC = () => {
  const location = useLocation();
  const [error, setError] = useState<string | null>(null);

  const getTokenFromUrl = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get("token");
  };

  const [formData, setFormData] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  const resetPassword = async () => {
    try {
      const resetToken = getTokenFromUrl();
      if (!resetToken) {
        setError("Invalid reset token");
        throw new Error("Invalid reset token");
      }
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/users/reset-password`,
        {
          reset_token: resetToken,
          new_password: formData.newPassword,
        }
      );
      toast.success("Password reset successful");
      return response.data;
    } catch (error: any) {
      toast.error("Error resetting password");
      setError(error.response.data.detail);
    }
  };

  const onChangeHandler = (value: string, field: keyof typeof formData) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setErrors({
      newPassword: "",
      confirmNewPassword: "",
    });

    let isValid = true;
    if (formData.newPassword !== formData.confirmNewPassword) {
      setErrors((prev) => ({
        ...prev,
        confirmNewPassword: "Passwords do not match",
      }));
      isValid = false;
    }

    if (isValid) {
      try {
        const resetToken = getTokenFromUrl();
        if (!resetToken) {
          setError("Invalid reset token");
          throw new Error("Invalid reset token");
        }
        const response = resetPassword();
        //@ts-ignore

        //@ts-ignore
        if (response?.message === "success") {
          navigate("/auth");
        }
      } catch (error) {
        console.log({ error });
      }
    }
  };

  return (
    <div className="w-full h-full p-4">
      <form
        onSubmit={submitHandler}
        className="w-[60%] mx-auto h-full py-4 space-y-8"
      >
        <div className="text-center w-full px-4 ">
          <h1 className="text-white text-2xl mb-4  pt-10">Reset Password</h1>
        </div>

        <div className="w-full flex justify-center space-y-4 text-white">
          <div className="w-full rounded-lg border border-header-border bg-primary p-4">
            <ScrollArea className="h-[calc(100vh-340px)]">
              <div className="space-y-4 px-4">
                <TextInput
                  type="password"
                  placeholder="New Password"
                  value={formData.newPassword}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "newPassword")
                  }
                  required
                  theme="white"
                  error={errors.newPassword}
                >
                  <label htmlFor="newPassword">New Password</label>
                </TextInput>

                <TextInput
                  type="password"
                  placeholder="Confirm New Password"
                  value={formData.confirmNewPassword}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "confirmNewPassword")
                  }
                  required
                  theme="white"
                  error={errors.confirmNewPassword}
                >
                  <label htmlFor="confirmNewPassword">
                    Confirm New Password
                  </label>
                </TextInput>
                {error && (
                  <div className="text-red-500 text-center">{error}</div>
                )}
              </div>
            </ScrollArea>
            <div className="flex justify-end mt-4">
              <button type="submit" className="px-4 py-1.5 bg-green rounded-lg">
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
