import React, { useEffect, useState } from "react";
import { TextInput, ScrollArea } from "../../components";
import * as Checkbox from "@radix-ui/react-checkbox";
import { CheckIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getRequest,
  postRequest,
  optimisticOptions,
  queries,
} from "../../react-query";

export const CreateOrganization = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [checked, setChecked] = React.useState(false);
  const [formData, setFormData] = useState<FormData>({
    name: "",
    primary_contact: {
      full_name: "",
      position: "",
      phone: "",
      email: "",
    },
    business_address: {
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      zip_code: "",
    },
    billing_address: {
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      zip_code: "",
      same_as_business: true,
    },
    billing_contact: {
      full_name: "",
      position: "",
      phone: "",
      email: "",
    },
    tax_number: "",
  });
  interface ContactInfo {
    full_name: string;
    position: string;
    phone: string;
    email: string;
  }

  interface Address {
    address_line1: string;
    address_line2: string;
    city: string;
    state: string;
    zip_code: string;
  }

  interface BillingAddress extends Address {
    same_as_business: boolean;
  }

  interface FormData {
    name: string;
    primary_contact: ContactInfo;
    business_address: Address;
    billing_address: BillingAddress;
    billing_contact: ContactInfo;
    tax_number: string;
  }

  interface ErrorState {
    name: string;
    primary_contact: ContactInfo;
    business_address: Address;
    billing_address: Address;
    billing_contact: ContactInfo;
    tax_number: string;
  }

  const [errors, setErrors] = useState<ErrorState>({
    name: "",
    primary_contact: {
      full_name: "",
      position: "",
      phone: "",
      email: "",
    },
    business_address: {
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      zip_code: "",
    },
    billing_address: {
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      zip_code: "",
    },
    billing_contact: {
      full_name: "",
      position: "",
      phone: "",
      email: "",
    },
    tax_number: "",
  });

  // Fetch organizations
  const { data: organizations } = useQuery("organizations", () =>
    getRequest("/organisations/list")
  );

  const onChangeHandler = (
    value: string,
    name: string,
    parent?: keyof Omit<FormData, "tax_number">
  ) => {
    if (parent) {
      setFormData((prevState) => ({
        ...prevState,
        [parent]: {
          ...(prevState[parent] as Record<string, any>),
          [name]: value,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const {
    mutate: createOrganization,
    isLoading,
    isError,
    error,
  } = useMutation(postRequest, {
    ...optimisticOptions(queries.organizations),
    onSuccess: (data) => {
      // Invalidate and refetch the organizations list
      queryClient.invalidateQueries([queries.organizations]);
      navigate(-1);
    },
    onError: (error: any) => {
      console.error("Failed to create organization:", error);
      console.error("Error response:", error.response?.data);
      // Handle error (e.g., show error message to user)
    },
  });

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();

    // Basic validation
    if (!formData.name.trim()) {
      setErrors((prev) => ({ ...prev, name: "Organization name is required" }));
      return;
    }

    const formattedData = {
      name: formData.name,
      primary_contact: formData.primary_contact,
      business_address: formData.business_address,
      billing_address: formData.billing_address,
      billing_contact: formData.billing_contact,
      tax_number: formData.tax_number,
    };

    createOrganization({
      endPoint: "/organisations",
      payload: formattedData,
    });
  };

  useEffect(() => {
    if (!checked) return;

    setFormData((prevdata) => {
      const updatedFormData = {
        ...prevdata,
        billing_address: {
          ...prevdata.business_address,
          same_as_business: checked,
        },
      };

      return updatedFormData;
    });
  }, [checked, formData]);

  return (
    <div className="w-full h-full p-10 bg-primary text-white">
      <form
        onSubmit={submitHandler}
        className="w-[65%] mx-auto h-full py-4 space-y-8"
      >
        <div className="rounded-lg border border-header-border bg-primary py-2 px-4 w-full flex justify-between items-center">
          <div className="space-y-2 lg:min-w-[500px]">
            <span className="text-perl text-base font-perl">
              New Organization
            </span>
          </div>
        </div>

        <div className="w-full flex justify-between space-x-4 text-white">
          <div className="w-full rounded-lg border border-header-border bg-primary p-4">
            <ScrollArea className="h-[calc(100vh-310px)]">
              <div className="space-y-6 px-4">
                {/* Organization Name */}
                <TextInput
                  type="text"
                  placeholder="Enter organization name"
                  value={formData.name}
                  onChange={(e) => onChangeHandler(e.target.value, "name")}
                  required
                  theme="white"
                  error={errors.name}
                >
                  <label htmlFor="name" className="text-white">
                    Organization Name
                  </label>
                </TextInput>

                {/* Primary Contact Section */}
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold">Primary Contact</h3>
                  <div className="grid grid-cols-2 gap-4">
                    <TextInput
                      type="text"
                      placeholder="Enter full name"
                      value={formData.primary_contact.full_name}
                      required
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "full_name",
                          "primary_contact"
                        )
                      }
                      theme="white"
                      error={errors.primary_contact.full_name}
                    >
                      <label htmlFor="primary_contact_full_name">
                        Full Name
                      </label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="Enter position"
                      value={formData.primary_contact.position}
                      required
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "position",
                          "primary_contact"
                        )
                      }
                      theme="white"
                      error={errors.primary_contact.position}
                    >
                      <label htmlFor="primary_contact_position">Position</label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="Enter phone"
                      required
                      value={formData.primary_contact.phone}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "phone",
                          "primary_contact"
                        )
                      }
                      theme="white"
                      error={errors.primary_contact.phone}
                    >
                      <label htmlFor="primary_contact_phone">Phone</label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="Enter email"
                      required
                      value={formData.primary_contact.email}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "email",
                          "primary_contact"
                        )
                      }
                      theme="white"
                      error={errors.primary_contact.email}
                    >
                      <label htmlFor="primary_contact_email">Email</label>
                    </TextInput>
                  </div>
                </div>

                {/* Business Address Section */}
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold">Business Address</h3>
                  <div className="grid grid-cols-2 gap-4">
                    <TextInput
                      type="text"
                      placeholder="Enter address line 1"
                      value={formData.business_address.address_line1}
                      required
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "address_line1",
                          "business_address"
                        )
                      }
                      theme="white"
                      error={errors.business_address.address_line1}
                    >
                      <label htmlFor="business_address_line1">
                        Address Line 1
                      </label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="Enter address line 2"
                      value={formData.business_address.address_line2}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "address_line2",
                          "business_address"
                        )
                      }
                      theme="white"
                      error={errors.business_address.address_line2}
                    >
                      <label htmlFor="business_address_line2">
                        Address Line 2
                      </label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="Enter city"
                      value={formData.business_address.city}
                      required
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "city",
                          "business_address"
                        )
                      }
                      theme="white"
                      error={errors.business_address.city}
                    >
                      <label htmlFor="business_city">City</label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="Enter state"
                      value={formData.business_address.state}
                      required
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "state",
                          "business_address"
                        )
                      }
                      theme="white"
                      error={errors.business_address.state}
                    >
                      <label htmlFor="business_state">State</label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="Enter zip code"
                      required
                      value={formData.business_address.zip_code}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "zip_code",
                          "business_address"
                        )
                      }
                      theme="white"
                      error={errors.business_address.zip_code}
                    >
                      <label htmlFor="business_zip_code">Zip Code</label>
                    </TextInput>
                  </div>
                </div>

                {/* Billing Address Section */}

                <div className="space-y-4">
                  <h3 className="text-lg font-semibold">Billing Address</h3>
                  <div className="flex items-center">
                    <Checkbox.Root
                      className="shadow-blackA4 hover:bg-violet3 flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-[4px] bg-white shadow-[0_2px_10px] outline-none focus:shadow-[0_0_0_2px_black]"
                      checked={checked}
                      onCheckedChange={() => setChecked(!checked)}
                      id="c1"
                    >
                      <Checkbox.Indicator className="text-violet11">
                        <CheckIcon />
                      </Checkbox.Indicator>
                    </Checkbox.Root>
                    <label
                      className="pl-[15px] font-medium leading-none text-gray-300"
                      htmlFor="c1"
                    >
                      Billing address same as business address
                    </label>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <TextInput
                      type="text"
                      placeholder="Enter address line 1"
                      disabled={checked}
                      value={formData.billing_address.address_line1}
                      required
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "address_line1",
                          "billing_address"
                        )
                      }
                      theme="white"
                      error={errors.billing_address.address_line1}
                    >
                      <label htmlFor="billing_address_line1">
                        Address Line 1
                      </label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="Enter address line 2"
                      disabled={checked}
                      value={formData.billing_address.address_line2}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "address_line2",
                          "billing_address"
                        )
                      }
                      theme="white"
                      error={errors.billing_address.address_line2}
                    >
                      <label htmlFor="billing_address_line2">
                        Address Line 2
                      </label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="Enter city"
                      disabled={checked}
                      required
                      value={formData.billing_address.city}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "city",
                          "billing_address"
                        )
                      }
                      theme="white"
                      error={errors.billing_address.city}
                    >
                      <label htmlFor="billing_city">City</label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="Enter state"
                      disabled={checked}
                      required
                      value={formData.billing_address.state}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "state",
                          "billing_address"
                        )
                      }
                      theme="white"
                      error={errors.billing_address.state}
                    >
                      <label htmlFor="billing_state">State</label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="Enter zip code"
                      disabled={checked}
                      required
                      value={formData.billing_address.zip_code}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "zip_code",
                          "billing_address"
                        )
                      }
                      theme="white"
                      error={errors.billing_address.zip_code}
                    >
                      <label htmlFor="billing_zip_code">Zip Code</label>
                    </TextInput>
                  </div>
                </div>

                {/* Billing Contact Section */}
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold">Billing Contact</h3>
                  <div className="grid grid-cols-2 gap-4">
                    <TextInput
                      type="text"
                      placeholder="Enter full name"
                      value={formData.billing_contact.full_name}
                      required
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "full_name",
                          "billing_contact"
                        )
                      }
                      theme="white"
                      error={errors.billing_contact.full_name}
                    >
                      <label htmlFor="billing_contact_full_name">
                        Full Name
                      </label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="Enter position"
                      value={formData.billing_contact.position}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "position",
                          "billing_contact"
                        )
                      }
                      theme="white"
                      error={errors.billing_contact.position}
                    >
                      <label htmlFor="billing_contact_position">Position</label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="Enter phone"
                      value={formData.billing_contact.phone}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "phone",
                          "billing_contact"
                        )
                      }
                      theme="white"
                      error={errors.billing_contact.phone}
                    >
                      <label htmlFor="billing_contact_phone">Phone</label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="Enter email"
                      value={formData.billing_contact.email}
                      required
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "email",
                          "billing_contact"
                        )
                      }
                      theme="white"
                      error={errors.billing_contact.email}
                    >
                      <label htmlFor="billing_contact_email">Email</label>
                    </TextInput>
                  </div>
                </div>

                {/* Tax Number */}
                <TextInput
                  type="text"
                  placeholder="Enter tax number"
                  value={formData.tax_number}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "tax_number")
                  }
                  theme="white"
                  error={errors.tax_number}
                >
                  <label htmlFor="tax_number">Tax Number</label>
                </TextInput>
              </div>
            </ScrollArea>
            <div className="flex justify-end mt-4">
              <button
                type="submit"
                className="bg-accent text-primary font-semibold px-6 py-2 rounded-full border-2 border-perl hover:bg-opacity-90 transition-colors duration-200 whitespace-nowrap"
              >
                Create Organization
              </button>
            </div>
          </div>
        </div>
      </form>
      {isLoading && <p>Creating organization...</p>}
      {isError && <p>Error: {(error as Error).message}</p>}
    </div>
  );
};
