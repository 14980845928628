import { Outlet } from "react-router-dom";
import { Footer, AnonymousHeader } from "../components";

export const AnonymousLayout = () => {
  return (
    <div className="w-screen h-screen">
      <AnonymousHeader />
      <div className="h-[calc(100vh-64px)]">
        <Outlet />
      </div>
    </div>
  );
};
