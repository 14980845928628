import React, { useEffect, useState } from "react";
import {
  CustomSelect,
  DefaultSelect,
  NormalSelect,
  NormalSwitch,
  ScrollArea,
  TextInput,
} from "../../components";
import { TextArea } from "../../components/input/TextArea";
import {
  ArrowLeftIcon,
  GearIcon,
  CardStackIcon,
  TrashIcon,
  LightningBoltIcon,
} from "@radix-ui/react-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  putRequest,
  queries,
} from "../../react-query";
import { format, parseISO } from "date-fns";
import { useStoreState } from "../../store/easy-peasy/hooks";
import { time } from "console";
import { update } from "lodash";

const providers = [
  {
    label: "Twilio",
    value: "Twilio",
  },
];

const durations = [
  {
    label: "30",
    value: 30,
  },
  {
    label: "60",
    value: 60,
  },
  {
    label: "45",
    value: 45,
  },
];

const max_slots = [
  {
    label: "0",
    value: 0,
  },
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },

  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },
];

export const UpdateCalendar = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useStoreState((store) => store.user);

  const { data: locations = [] } = useQuery([queries.locations], () =>
    getRequest(`/locations/list`)
  );

  const { data: time_zones = [] } = useQuery([queries.time_zones], () =>
    getRequest(`/calendars/time-zones`)
  );

  const { data: calendar = {} } = useQuery(
    [queries.calendars, id],
    () => getRequest(`/calendars/?calendar_id=${id}`),
    {
      enabled: !!id,
    }
  );

  // Fetch organization list
  const { data: organizations = [] } = useQuery(
    "organizations",
    () => getRequest(`/organisations/list`),
    {
      enabled: true,
    }
  );
  const [formData, setFormData] = useState<any>({
    org_name: "",
    name: "",
    title: "",
    purpose: "",
    duration: {
      label: "",
      value: "",
    },
    start_hours: "",
    closing_hours: "",
    max_slot: {
      label: "",
      value: "",
    },
    provider: "",
    api_key: "",
    event_id: "",
    location: {
      label: "",
      value: "",
    },
    status: true,
    time_zone: {
      label: "",
      value: "",
    },
  });

  const [errors, setErrors] = useState({
    title: "",
    purpose: "",
    duration: "",
    start_hours: "",
    closing_hours: "",
    max_slot: "",
    provider: "",
    location: "",
    api_key: "",
    event_id: "",
    status: "",
    time_zone: "",
  });

  const onChangeHandler = (value: string | boolean, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const { mutate: updateCalendar } = useMutation(
    putRequest,
    optimisticOptions(queries.calendars)
  );

  const submitHandler = (e: any) => {
    e.preventDefault();

    const {
      org_name,
      name,
      title,
      purpose,
      provider,
      location,
      start_hours,
      duration,
      closing_hours,
      event_id,
      api_key,
      max_slot,
      time_zone,
      status,
    } = formData;
    updateCalendar({
      endPoint: `/calendars/?calendar_id=${id}`,
      payload: {
        org_name: org_name,
        name: name,
        title: title,
        purpose: purpose,
        location_id: location.value,
        start_hours,
        closing_hours,
        duration: duration.value,
        max_slots: max_slot.value,
        api_key: {
          eventId: event_id,
          key: api_key,
        },
        provider: provider.value,
        status: status === true ? 1 : 0,
        time_zone: time_zone.value,
        org_id: user.org_id,
      },
    });
    navigate(-1);
  };

  useEffect(() => {
    if (calendar) {
      const {
        name,
        title,
        purpose,
        start_hours,
        closing_hours,
        max_slots,
        duration,
        provider,
        api_key,
        location,
        status,
        org_name, 
      } = calendar;
      setFormData({
        ...formData,
        title,
        purpose,
        start_hours: start_hours?.slice(0, -1),
        closing_hours: closing_hours?.slice(0, -1),
        provider: {
          label: provider,
          value: provider,
        },
        max_slot: {
          label: max_slots,
          value: max_slots,
        },
        duration: {
          label: duration,
          value: duration,
        },
        api_key: api_key?.key,
        event_id: api_key?.eventId,
        location: {
          label: location?.name,
          value: location?.id,
        },
        time_zone: {
          label: calendar.time_zone,
          value: calendar.time_zone,
        },
        status: status === 1 ? true : false,
        org_name: org_name, 
      });
    }
  }, [calendar]);

  // Find the organization name based on org_id from calendar
  const organizationName = organizations.find(
    (org: { id: string }) => org.id === calendar.org_id
  )?.name;


  
  return (
    <>
      {formData && (
        <div className="w-full h-full bg-primary text-white">
          <form
            onSubmit={submitHandler}
            className="w-[65%] mx-auto h-full py-4 space-y-8"
          >
            <div className="rounded-lg border border-header-border bg-primary py-2 px-4 w-full flex flex-col">
              {organizationName && (
                <h2 className="text-xl  mb-2 text-white">
                  Organization : {organizationName} {/* Display organization name */}
                </h2>
              )}

              <div className="flex space-x-6 text-white">
                <span className="text-perl text-lg font-perl">
                  Update Calendar
                </span>
              </div>
            </div>

            <div className="w-full flex justify-between space-x-4 text-white">
              <div className="w-full rounded-lg  border  border-header-border bg-primary   p-4">
                <ScrollArea className="h-[calc(110vh-310px)] ">
                  <div className="space-y-2 grid grid-cols-2 gap-x-5 gap-y-4 px-4">
                    <TextInput
                      type="text"
                      placeholder="San Jose service booking"
                      value={formData.title}
                      onChange={(e) => onChangeHandler(e.target.value, "title")}
                      required
                      theme="white"
                      error={errors.title}
                    >
                      <label htmlFor="title">Name</label>
                    </TextInput>

                    <TextInput
                      type="text"
                      placeholder="Book all the services for San Jose using this calendar"
                      value={formData.purpose}
                      onChange={(e) =>
                        onChangeHandler(e.target.value, "purpose")
                      }
                      required
                      theme="white"
                      error={errors.purpose}
                    >
                      <label htmlFor="Purpose">Prompt</label>
                    </TextInput>

                    <TextInput
                      type="time"
                      placeholder="Enter"
                      value={formData.start_hours}
                      onChange={(e) =>
                        onChangeHandler(e.target.value, "start_hours")
                      }
                      required
                      theme="white"
                      error={errors.start_hours}
                    >
                      <label htmlFor="start_hours">Work Hours(Start)</label>
                    </TextInput>

                    <TextInput
                      type="time"
                      placeholder="Enter"
                      value={formData.closing_hours}
                      onChange={(e) =>
                        onChangeHandler(e.target.value, "closing_hours")
                      }
                      required
                      theme="white"
                      error={errors.closing_hours}
                    >
                      <label htmlFor="closing_hours">Work Hours(End)</label>
                    </TextInput>

                    <DefaultSelect
                      theme="white"
                      data={providers}
                      value={formData.provider}
                      defaultValue={formData.provider}
                      onValueChange={(value: any) =>
                        onChangeHandler(value, "provider")
                      }
                      placeholder="Select"
                      keyField="value"
                      valueField="label"
                    >
                      <label htmlFor="Welcome">Provider</label>
                    </DefaultSelect>

                    <DefaultSelect
                      theme="white"
                      data={durations}
                      value={formData.duration}
                      defaultValue={formData.duration}
                      onValueChange={(value: any) =>
                        onChangeHandler(value, "duration")
                      }
                      placeholder="Select"
                      keyField="value"
                      valueField="label"
                    >
                      <label htmlFor="duration">Duration</label>
                    </DefaultSelect>

                    <TextInput
                      type="text"
                      placeholder="API Key from the provider"
                      value={formData.api_key}
                      onChange={(e) =>
                        onChangeHandler(e.target.value, "api_key")
                      }
                      required
                      theme="white"
                      error={errors.api_key}
                    >
                      <label htmlFor="api_key">API Key</label>
                    </TextInput>

                    <TextInput
                      type="text"
                      placeholder="Event ID"
                      value={formData.event_id}
                      onChange={(e) =>
                        onChangeHandler(e.target.value, "event_id")
                      }
                      required
                      theme="white"
                      error={errors.event_id}
                    >
                      <label htmlFor="event_id">Event ID</label>
                    </TextInput>

                    <DefaultSelect
                      theme="white"
                      data={locations}
                      defaultValue={formData.location}
                      value={formData.location}
                      onValueChange={(value: any) =>
                        onChangeHandler(value, "location")
                      }
                      placeholder="Select"
                      keyField="id"
                      valueField="name"
                    >
                      <label htmlFor="duration">Location</label>
                    </DefaultSelect>

                    <DefaultSelect
                      theme="white"
                      data={time_zones.map((timezone: any) => ({
                        label: timezone,
                        value: timezone,
                      }))}
                      defaultValue={formData.time_zone}
                      value={formData.time_zone}
                      onValueChange={(value: any) =>
                        onChangeHandler(value, "time_zone")
                      }
                      placeholder="Select"
                      keyField="value"
                      valueField="label"
                    >
                      <label htmlFor="duration">Time Zone</label>
                    </DefaultSelect>

                    <DefaultSelect
                      theme="white"
                      data={max_slots}
                      defaultValue={formData.max_slot}
                      value={formData.max_slot}
                      onValueChange={(value: any) =>
                        onChangeHandler(value, "max_slot")
                      }
                      placeholder="Select"
                      keyField="value"
                      valueField="label"
                    >
                      <label htmlFor="max_slot">
                        Simultaneous Appointments Allowed
                      </label>
                    </DefaultSelect>
                  </div>
                </ScrollArea>
                <div className="flex justify-end">
                  {user.access_level > 0 ? (
                    <button
                      type="submit"
                      className="bg-accent text-primary font-semibold px-6 py-2 rounded-full border-2 border-perl hover:bg-opacity-90 transition-colors duration-200 whitespace-nowrap"
                    >
                      Save
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};
