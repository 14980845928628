import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getRequest, queries } from "../../react-query";
import { DropdownMenu } from "../../components";
import { format } from "date-fns";
import { MixerVerticalIcon } from "@radix-ui/react-icons";
import { useStoreState } from "../../store/easy-peasy/hooks";

// Tabs for toggling between different states
const tabs = [
  {
    id: "1",
    name: "Active",
    value: "ACTIVE",
  },
  {
    id: "2",
    name: "Draft",
    value: "DRAFT",
  },
  {
    id: "3",
    name: "Deleted",
    value: "DELETED",
  },
];

// Filter menu for dropdown options
const filter_menu = [
  {
    id: "1",
    name: "Today",
    path: "",
  },
  {
    id: "2",
    name: "Yesterday",
    path: "",
  },
  {
    id: "3",
    name: "This Week",
    path: "",
  },
  {
    id: "4",
    name: "This Month",
    path: "",
  },
];

// Define the type for the API data
interface HomeStats {
  total_calls: number;
  inbound_calls: number;
  outbound_calls: number;
  total_call_duration: number;
  inbound_calls_duration: number;
  outbound_calls_duration: number;
  total_appointments: number;
  booked_appointments: number;
  cancelled_appointments: number;
}

// Define the prop types for StatCard component
interface StatCardProps {
  title: string;
  main_value: number | string; // Allow number or string to accommodate different value types
  second_value: number | string; // Allow number or string to accommodate different value types
}

export function HomeDashboard() {
  const [input, setInput] = useState({
    tab: "ACTIVE",
  });

  const user = useStoreState((store) => store.user);
  const [selectedFilter, setSelectedFilter] = useState(filter_menu[3].name);

  // Fetching the statistics data using react-query
  const {
    data: homestats,
    isLoading,
    isError,
  } = useQuery<HomeStats>([queries.homestats], () =>
    getRequest("/statistics/all")
  );

  // Handling loading state
  if (isLoading) {
    return <div className="text-center text-white">Loading...</div>;
  }

  // Handling error state
  if (isError) {
    return <div className="text-center text-red-500">Error loading data.</div>;
  }

  // Check if the data is received and is an object
  if (!homestats || typeof homestats !== "object") {
    return (
      <div className="text-center text-gray-500">
        Data is not available or not in the correct format.
      </div>
    );
  }


  return (
    <div className="w-full h-full p-10 text-white grid place-content-top bg-primary ">
      <div className="pt-50">
        <p className="pt-5 text-center text-lg text-gray-300">
          {format(new Date(), "EEEE, MMMM dd")}
        </p>
        <p className="pt-5 pb-20 text-center text-2xl">
          {getGreeting()},{" "}
          <span className="text-2xl font-black text-logo font-custom letter-spacing: ">
            {user.name}
          </span>
        </p>
      </div>

      {/* Render statistics */}
      <div className="bg-dark-background p-5 rounded-lg shadow-lg">
        <div className="rounded-lg h-[80px] w-[200px] py-2 px-4 flex justify-between items-center mb-5">
          <div className="space-y-2 lg:min-w-[100px]">
            <DropdownMenu
              innerStyle="min-w-[180px]  text-perl flex flex-col rounded-md bg-secondary shadow-xl z-100 m-2 py-2"
              arrowStyle="fill-secondary"
              isArrowShow={false}
              data={filter_menu}
            >
              <div className="flex items-center space-x-2 cursor-pointer p-2 rounded">
                <MixerVerticalIcon className="text-white" />
                <span className="text-perl">
                  {selectedFilter ?? "Filter"}
                </span>{" "}
              </div>
            </DropdownMenu>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6 lg:gap-10">
          <StatCard
            title="Total Calls"
            main_value={homestats.total_calls}
            second_value={(homestats.total_call_duration / 60).toFixed(1)}
          />
          <StatCard
            title="Inbound Calls"
            main_value={homestats.inbound_calls}
            second_value={(homestats.inbound_calls_duration / 60).toFixed(1)}
          />
          <StatCard
            title="Outbound Calls"
            main_value={homestats.outbound_calls}
            second_value={(homestats.outbound_calls_duration / 60).toFixed(1)}
          />

          <StatCard
            title="Total Appointments"
            main_value={homestats.total_appointments}
            second_value={""}
          />
          <StatCard
            title="Booked Appointments"
            main_value={homestats.booked_appointments}
            second_value={""}
          />
          <StatCard
            title="Cancelled Appointments"
            main_value={homestats.cancelled_appointments}
            second_value={""}
          />
          <div className="col-span-4">{/* Other potential content */}</div>
        </div>
      </div>
    </div>
  );
}

// Function to get a greeting message based on the time of day
function getGreeting(): string {
  const currentHour = new Date().getHours();
  if (currentHour < 12) {
    return "Good Morning";
  } else if (currentHour < 18) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
}

// StatCard Component for Reusability and Cleaner Code
const StatCard: React.FC<StatCardProps> = ({
  title,
  main_value,
  second_value,
}) => {
  return (
    <div className="p-6 bg-primary rounded-lg text-center shadow-lg border-2 border-header-border hover:border-white hover:shadow-2xl transition-all duration-300 ease-in-out">
      <p className="text-l font-medium text-perl h-[50px]">{title}</p>
      <p className="text-3xl font-bold">{main_value}</p>
      {second_value && (
        <p className="text-sm text-gray-500">{second_value} minutes</p>
      )}
    </div>
  );
};
