import React, { useState } from "react";
import { NormalSelect, TextInput, ScrollArea } from "../../components";
import { TextArea } from "../../components/input/TextArea";
import { MultiSelect } from "../../components/select/MultiSelect";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getRequest, putRequest, queries } from "../../react-query";

interface FormData {
  name: string;
  description: string;
  prompt_template: string;
  tasks: string[];
  orgId: string;
  category: string;
  channel: string;
  status: string;
  imagePath: string;
}

interface Errors {
  name: string;
  description: string;
  prompt_template: string;
  tasks: string;
  orgId: string;
  category: string;
  channel: string;
  status: string;
}

interface Organization {
  id: string;
  name: string;
}

interface AgentTemplate {
  id: string;
  name: string;
  description: string;
  prompt_template: string;
  tasks: string[];
  org_id: string | null;
  category: string;
  channel: string;
  status: string;
  image_path: string;
  created_at: string;
  updated_at: string;
  version: number;
  metadata: any | null;
}

const fetchAgentTemplate = async (id: string | undefined) => {
  if (!id) throw new Error("No template ID provided");
  const response = await getRequest(
    `/v2/agent_templates/?agent_template_id=${id}`
  );
  if (Array.isArray(response)) {
    return response[0];
  } else if (typeof response === "object" && response !== null) {
    return response;
  }
  throw new Error("Invalid data received from API");
};

export const UpdateAgentTemplate: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();

  const statusOptions = ["active", "draft"];
  const categoryOptions = [
    "service",
    "sales and service",
    "follow up",
    "lead generation",
    "customer engagement",
    "marketing",
    "technical support",
  ];
  const channelOptions = ["sms", "call", "web", "email"];

  const [formData, setFormData] = useState<FormData>({
    name: "",
    description: "",
    prompt_template: "",
    tasks: [],
    orgId: "",
    category: "",
    channel: "",
    status: "active",
    imagePath: "",
  });

  const [errors, setErrors] = useState<Errors>({
    name: "",
    description: "",
    prompt_template: "",
    tasks: "",
    orgId: "",
    category: "",
    channel: "",
    status: "",
  });

  const { data: organizations = [], isLoading: orgLoading } = useQuery<
    Organization[]
  >([queries.organizations], () => getRequest("/organisations/list"), {
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const {
    data: agentTemplate,
    isLoading: agentLoading,
    error: agentError,
  } = useQuery<AgentTemplate>(
    [queries.agentTemplates, id],
    () => fetchAgentTemplate(id),
    {
      enabled: !!id,
      onSuccess: (data) => {
        if (data && typeof data === "object" && "name" in data) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            name: data.name || "",
            description: data.description || "",
            prompt_template: data.prompt_template || "",
            tasks: Array.isArray(data.tasks) ? data.tasks : [],
            orgId: data.org_id || "",
            category: data.category || "",
            channel: data.channel || "",
            status: data.status || "active",
            imagePath: data.image_path || "",
          }));
        } else {
          console.error("Invalid data received from API:", data);
        }
      },
    }
  );

  const taskOptions: string[] = [];

  function onChangeHandler(value: any, key: keyof FormData) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value,
    }));
  }

  const { mutate: updateAgentTemplate, isLoading: isSubmitting } = useMutation(
    (payload: Partial<AgentTemplate>) =>
      putRequest({
        endPoint: `/v2/agent_templates/?agent_template_id=${id}`,
        payload,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          queries.agentTemplates,
          formData.orgId,
          "active",
        ]);
        navigate("/agent-templates");
      },
      onError: (error: any) => {
        console.error("Failed to update agent template:", error);
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          console.error("Response headers:", error.response.headers);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error message:", error.message);
        }
        if (error.response && error.response.data) {
          setErrors({
            ...errors,
            name: error.response.data.name || errors.name,
            description: error.response.data.description || errors.description,
            prompt_template:
              error.response.data.prompt || errors.prompt_template,
            tasks: error.response.data.tasks || errors.tasks,
            orgId: error.response.data.org_id || errors.orgId,
            category: error.response.data.category || errors.category,
            channel: error.response.data.channel || errors.channel,
            status: error.response.data.status || errors.status,
          });
        } else {
          setErrors({
            ...errors,
            name: "An unknown error occurred. Please try again.",
          });
        }
      },
    }
  );

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {
      name,
      description,
      prompt_template,
      orgId,
      category,
      channel,
      status,
      imagePath,
      tasks,
    } = formData;

    if (
      !name ||
      !description ||
      !prompt_template ||
      !category ||
      !channel ||
      !status ||
      !orgId
    ) {
      setErrors({
        name: !name ? "Name is required" : "",
        description: !description ? "Description is required" : "",
        prompt_template: !prompt_template ? "Prompt is required" : "",
        tasks: "",
        orgId: !orgId ? "Organization is required" : "",
        category: !category ? "required" : "",
        channel: !channel ? "required" : "",
        status: !status ? "Status is required" : "",
      });
      return;
    }

    const payload = {
      name: name,
      description: description,
      prompt: prompt_template,
      metadata: {},
      category: category,
      channel: channel,
      image_path: imagePath || "",
      status: status,
      tasks: tasks.length > 0 ? tasks : [],
      org_id: orgId,
    };

    updateAgentTemplate(payload);
  };

  if (agentLoading) {
    return <div>Loading agent template...</div>;
  }

  if (agentError) {
    return (
      <div>
        Error loading agent template:{" "}
        {agentError instanceof Error ? agentError.message : "Unknown error"}
      </div>
    );
  }

  return (
    <div className="w-full h-screen bg-primary text-white py-4">
      <div className="rounded-lg border border-header-border bg-primary py-2 px-4 w-[60%]">
        <div className="flex space-x-6 text-white">
          <span className="text-perl text-lg font-perl">
            Update Agent Template
          </span>
        </div>
      </div>
      <form onSubmit={submitHandler} className="h-full py-4 space-y-4">
        <div className="w-[60%] rounded-lg border items-center border-header-border bg-primary py-4 px-4 flex justify-between space-x-4">
          <div className="space-y-2">
            <div className="w-full flex justify-start space-x-4">
              <div className="flex items-center">
                <span className="text-xl text-center">Organization : </span>
                {orgLoading ? (
                  <div>Loading...</div>
                ) : (
                  <NormalSelect
                    theme="white"
                    className="border-none text-nowrap"
                    data={[
                      ...organizations.map((org) => ({
                        id: org.id,
                        name: org.name,
                        value: org.id,
                      })),
                    ]}
                    onValueChange={(value: string) =>
                      onChangeHandler(value, "orgId")
                    }
                    value={formData.orgId}
                    placeholder="Select Organization"
                    keyField="id"
                    valueField="name"
                    required={true}
                    error={errors.orgId}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-between space-x-4 text-offwhite">
          <div className="w-[60%] rounded-lg border border-header-border bg-primary p-4">
            <ScrollArea className="h-[calc(100vh-240px)]">
              <div className="space-y-2">
                <TextInput
                  type="text"
                  placeholder="Agent Name"
                  value={formData.name}
                  onChange={(e) => onChangeHandler(e.target.value, "name")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                  error={errors.name}
                >
                  <label htmlFor="name" className="pt-4">
                    Name
                  </label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Description of the agent"
                  value={formData.description}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "description")
                  }
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                  error={errors.description}
                >
                  <label htmlFor="description" className="pt-4">
                    Description
                  </label>
                </TextInput>

                <TextArea
                  className="focus:outline-none min-h-[44vh] bg-transparent rounded-lg border border-header-border p-2"
                  placeholder="Instructions for your agent"
                  value={formData.prompt_template}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "prompt_template")
                  }
                  required
                  theme="white"
                  error={errors.prompt_template}
                >
                  <label htmlFor="prompt" className="pt-4">
                    Prompt
                  </label>
                </TextArea>

                <MultiSelect
                  theme="black"
                  keyField="id"
                  valueField="name"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                  data={taskOptions.map((task, index) => ({
                    id: index.toString(),
                    name: task,
                  }))}
                  onValueChange={(selectedOptions) => {
                    const selectedTasks = selectedOptions.map(
                      (opt: { label: string; value: string }) => opt.label
                    );
                    onChangeHandler(selectedTasks, "tasks");
                  }}
                  value={formData.tasks.map((task) => ({
                    value: taskOptions.indexOf(task).toString(),
                    label: task,
                  }))}
                  defaultValue={[]}
                  placeholder="Select Tasks"
                >
                  <label htmlFor="tasks">Tasks (Optional)</label>
                </MultiSelect>

                <div className="flex flex-col gap-3">
                  <label htmlFor="category" className="pt-4">
                    Category{" "}
                    <span className="text-sm text-red-600 w-full">
                      {errors.category}
                    </span>
                  </label>
                  <div className="w-full rounded-lg border  border-header-border bg-primary">
                    <NormalSelect
                      theme="white"
                      className="border-none text-nowrap"
                      data={[
                        ...categoryOptions.map((category) => ({
                          id: category,
                          value: category,
                          name: category,
                        })),
                      ]}
                      onValueChange={(value: string) =>
                        onChangeHandler(value, "category")
                      }
                      value={formData.category}
                      placeholder="Select category"
                      keyField="id"
                      valueField="value"
                      required={true}
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-3">
                  <label htmlFor="Channel" className="pt-4">
                    Channel{" "}
                    <span className="text-sm text-red-600 w-full">
                      {errors.channel}
                    </span>
                  </label>
                  <div className="w-full rounded-lg border  border-header-border bg-primary">
                    <NormalSelect
                      theme="white"
                      className="border-none text-nowrap"
                      data={[
                        ...channelOptions.map((channel) => ({
                          id: channel,
                          value: channel,
                          name: channel,
                        })),
                      ]}
                      onValueChange={(value: string) =>
                        onChangeHandler(value, "channel")
                      }
                      value={formData.channel}
                      placeholder="Select channel"
                      keyField="id"
                      valueField="value"
                      required={true}
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-3">
                  <label htmlFor="status" className="pt-4">
                    Status
                    <span className="text-sm text-red-600 w-full">
                      {errors.status}
                    </span>
                  </label>
                  <div className="w-full rounded-lg border  border-header-border bg-primary">
                    <NormalSelect
                      theme="white"
                      className="border-none text-nowrap"
                      data={[
                        ...statusOptions.map((status) => ({
                          id: status,
                          value: status,
                          name: status,
                        })),
                      ]}
                      onValueChange={(value: string) =>
                        onChangeHandler(value, "status")
                      }
                      value={formData.status}
                      placeholder="Status"
                      keyField="id"
                      valueField="value"
                      required={true}
                      error={errors.status}
                    />
                  </div>
                </div>
                <TextInput
                  type="text"
                  placeholder="Image Path"
                  value={formData.imagePath}
                  onChange={(e) => onChangeHandler(e.target.value, "imagePath")}
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="imagePath" className="pt-4">
                    Image Path (Optional)
                  </label>
                </TextInput>

                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="bg-accent text-primary font-semibold px-6 py-2 rounded-full border-2 border-perl hover:bg-opacity-90 transition-colors duration-200 whitespace-nowrap"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Saving..." : "Update"}
                  </button>
                </div>
              </div>
            </ScrollArea>
          </div>
        </div>
      </form>
    </div>
  );
};
