import React, { useEffect, useState } from "react";
import { validateField, validateForm } from "../../utils";
import { TextInput, CheckBox } from "..";
import hide from "../../assets/images/hide.svg";
import unhide from "../../assets/images/unhide.svg";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { postRequest } from "../../react-query";
import { useStoreActions } from "../../store/easy-peasy/hooks";

const validationRules = {
  firstName: [{ required: true, message: "Please enter first name" }],
  lastName: [{ required: true, message: "Please enter last name" }],
  email: [
    { required: true, message: "Please enter your email" },
    { pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: "Invalid email format" },
  ],
  password: [
    { required: true },
    {
      pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
      message:
        "Password must be at least 6 characters and include at least one uppercase letter, one lowercase letter, and one number",
    },
  ],
  isTermsAccepted: [
    { required: true, message: "Please accept the terms and conditions" },
  ],

  isPromotionsAccepted: [
    { required: false, message: "Please accept Promotions" },
  ],
};

export function SignupForm({ setAuthPage }: any) {
  const { data: signupResponse, mutate: signup } = useMutation(postRequest);
  const [isLoading, setIsLoading] = useState(false);

  let addUser = useStoreActions((store) => store.addUser);
  const naviagte = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    isTermsAccepted: false,
    isPromotionsAccepted: false,
  });

  const [isHide, setIsHide] = useState(true);

  const [errors, setErrors] = useState<Record<string, string>>({});

  const onChangeHandler = (value: string | boolean, name: string) => {
    setFormData({ ...formData, [name]: value });
    // Validate field on change
    const fieldErrors = validateField(name, value, validationRules);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: fieldErrors.length > 0 ? fieldErrors[0] : "",
    }));
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();

    const validationErrors = validateForm(formData, validationRules);
    if (Object.keys(validationErrors).length === 0) {
      try {
        signup({
          endPoint: `/users/`,
          payload: {
            first_name: formData.firstName,
            last_name: formData.lastName,
            email: formData.email,
            image: "",
            gender: "MALE",
            roles: ["MANAGER"],
            password: formData.password,
          },
        });
      } catch (error) {}
      setErrors({});
    } else {
      // Form has errors, update state with validation errors
      setErrors(validationErrors);
    }
  };

  useEffect(() => {
    let timeoutId: any;

    if (signupResponse && signupResponse.token) {
      setAuthPage("SigninForm");
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [signupResponse, addUser, naviagte]);

  return (
    <form className="h-w-full w-full space-y-5" onSubmit={onSubmitHandler}>
      <TextInput
        type="text"
        value={formData.firstName}
        onChange={(e) => onChangeHandler(e.target.value, "firstName")}
        required
        theme="black"
        error={errors.firstName}
      >
        <label className="" htmlFor="Username">
          Username
        </label>
      </TextInput>

      <TextInput
        type="email"
        value={formData.email}
        onChange={(e) => onChangeHandler(e.target.value, "email")}
        required
        theme="black"
        error={errors.email}
      >
        <label className="" htmlFor="Email">
          Email
        </label>
      </TextInput>

      <TextInput
        type={isHide ? "password" : "text"}
        value={formData.password}
        onChange={(e) => onChangeHandler(e.target.value, "password")}
        required
        theme="black"
        error={errors.password}
      >
        <div className="flex justify-between items-center">
          <label className="" htmlFor="Password">
            Password
          </label>
          <button
            onClick={() => setIsHide(!isHide)}
            className="inline-flex items-center space-x-1"
          >
            {isHide ? (
              <img className="w-4 h-4" src={hide} alt="view" />
            ) : (
              <img className="w-4 h-4" src={unhide} alt="view" />
            )}
            <span>{isHide ? "Hide" : "View"}</span>
          </button>
        </div>
      </TextInput>

      <CheckBox
        checked={formData.isTermsAccepted}
        onChange={(e) => onChangeHandler(e.target.checked, "isTermsAccepted")}
        error={errors.isTermsAccepted}
      >
        By creating an account, I agree to our Terms of use and Privacy Policy.
      </CheckBox>

      <button
        className="w-full rounded-full bg-secondary text-white py-2"
        type="submit"
      >
        {isLoading ? "Loading" : "Register"}
      </button>
    </form>
  );
}
