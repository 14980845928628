import React from "react";
import { StylesConfig } from "react-select";
import CreatableSelect from "react-select/creatable";

interface NormalSelectProps {
  data: Array<{ [key: string]: any }>;
  onValueChange: (value: any) => void;
  defaultValue: any;
  placeholder: string;
  className?: string;
  value?: any;
  theme?: "black" | "white";
  keyField: string;
  valueField: string;
  disabled?: boolean;
  menuPlacement?: any;
  children?: React.ReactNode;
  required?: boolean;
}

export const MultiSelect: React.FC<NormalSelectProps> = ({
  data,
  onValueChange,
  defaultValue,
  children,
  placeholder,
  className,
  value,
  theme = "black",
  keyField,
  valueField,
  disabled,
  menuPlacement,
  required,
  ...props
}) => {
  const customStyles: StylesConfig<any, true> = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      background: "#2e2e30",
      caretColor: "white",
      color: theme === "black" ? "white" : "black",
      borderColor: "#505050",
      "&:hover": {
        borderColor: "#FFFFFF",
      },
      "&:focus": {
        borderColor: "#DFDCDC",
      },
      "&:active": {
        borderColor: "#FFFFFF",
      },
      boxShadow: "none",
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#fff",
    }),
    menu: (provided) => ({
      ...provided,
      background: "#505050",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      color: "#fff",
      backgroundColor: "whitesmoke",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "black",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      backgroundColor: "#ff6666",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#ff4c4c",
        color: "#fff",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#3a3a3a" : "inherit",
      "&:hover": {
        backgroundColor: state.isFocused ? "#555555" : "inherit",
      },
      color: "#fff",
    }),
  };

  return (
    <div className="space-y-1">
      {children}
      <CreatableSelect
        menuPlacement={menuPlacement}
        className={`w-full inline-flex items-center h-10 justify-between`}
        styles={customStyles}
        placeholder={placeholder}
        options={data?.map((item) => ({
          label: item[valueField],
          value: item[keyField],
        }))}
        isClearable={false}
        value={value}
        defaultValue={defaultValue}
        onChange={(selectedOptions) => onValueChange(selectedOptions || [])}
        isMulti
        {...props}
        required={required}
      />
    </div>
  );
};
