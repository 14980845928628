import React, { useEffect, useState } from "react";
import * as Dialog_ from "@radix-ui/react-dialog";
import { NormalSelect } from "../select";

let countries = [
  {
    id: "IN",
    value: "+91",
    name: "🇮🇳",
  },
  {
    id: "US",
    value: "+1",
    name: "🇺🇸",
  },
];

export const ConfirmDialog = ({
  isOpen,
  onClose,
  onConfirm,
  error,
  children,
  testNumber,
  onChangeHandler
}: any) => {

  

  return (
    <Dialog_.Root open={isOpen}>
      <Dialog_.Trigger asChild>{children}</Dialog_.Trigger>
      <Dialog_.Portal>
        <Dialog_.Overlay
          onClick={() => onClose()}
          className="bg-white opacity-10 data-[state=open]:animate-overlayShow fixed inset-0"
        />
        <Dialog_.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
          <Dialog_.Title className="text-lg font-bold">
            Call and Test 
          </Dialog_.Title>

          <div className="relative space-y-1 w-full max-w-md h-full md:h-auto text-black text-center mt-8">
            <fieldset className="Fieldset flex space-x-4 items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6 text-green"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                />
              </svg>

              <div className="inline-flex space-x-2 border border-gray">
                <select
                  onChange={(e) => onChangeHandler(e.target.value, "countryCode")}
                  className="py-2 px-2 focus:outline-none"
                >
                  <option>🌐</option>
                  {countries.map((country) => {
                    return (
                      <>
                        <option value={country.value}>{country.name}</option>
                      </>
                    );
                  })}
                </select>

                <input
                  className="px-2  focus:outline-none rounded"
                  placeholder="Enter phone number"
                  value={testNumber}
                  onChange={(e) => {
                    onChangeHandler(e.target.value, "testNumber")
                  }}
                />
              </div>
            </fieldset>
            
          </div>

          <div className="mt-[35px] flex justify-end space-x-4">
            <button
              disabled={error}
              onClick={() => onClose()}
              data-modal-toggle="deleteModal"
              type="button"
              className="py-2 px-3 text-sm font-medium text-black bg-white rounded-lg border border-gray-200 hover:bg-gray-100  dark:bg-gray-700  dark:border-gray-500  dark:hover:bg-gray-600"
            >
              Cancel
            </button>

            <button
              onClick={() => onConfirm()}
              className="py-2 px-3 text-sm font-medium text-black bg-white rounded-lg border border-green hover:bg-green   dark:bg-green dark:text-white dark:border-green dark:hover:bg-green "
            >
              Call
            </button>
          </div>
        </Dialog_.Content>
      </Dialog_.Portal>
    </Dialog_.Root>
  );
};
