import { SidebarFooter } from "./sidebar-footer";
import { renderSidebars } from "../../routes";
import { Link, NavLink } from "react-router-dom";
import { PlusIcon, ClipboardIcon } from "@radix-ui/react-icons";
import { DropdownMenu } from "../dropdown";

export const Sidebar = ({ isOpen, onClose }: any) => {

  return (
    <div
      className={`relative bg-primary max-h-[90vh] transform transition-opacity duration-1000 ease-in-out ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <div
        className={`min-w-60 flex flex-col space-y-3 py-3 item-center text-white ${
          isOpen ? "visible" : "hidden"
        } overflow-y-auto`}
      >
        <div className="flex flex-col space-y-3 w-[88%] mx-auto">
          {renderSidebars([
            "home",
            "calls",
            "agents",
            "phones",
            "locations",
            "calendars",
            "integrations",
            "organizations",
            "knowledge-bases",
            "agent-templates",
          ]).map((data: any) => (
            <NavLink
              key={data.title}
              to={data.path}
              style={({ isActive }) => {
                return isActive
                  ? { backgroundColor: "#505050" }
                  : { backgroundColor: "transparent" };
              }}
              className="inline-flex space-x-2 py-0.5 items-center text-[#FFFFFE] rounded-md px-2"
            >
              {data.icon}
              <p>{data.title}</p>
            </NavLink>
          ))}
        </div>

        <div className="border-t border-header-border" />

        <SidebarFooter />
      </div>
    </div>
  );
};
