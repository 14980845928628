import React, { useEffect, useMemo, useState } from "react";
import { List, ScrollArea, ToggleGroup } from "../../components";
import { useQuery } from "react-query";
import { getRequest, queries } from "../../react-query";
import axios from "axios";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useStoreState } from "../../store/easy-peasy/hooks";

const tabs = [
  {
    id: "1",
    name: "Active",
    value: 1,
  },
  {
    id: "2",
    name: "Draft",
    value: 2,
  },
  {
    id: "3",
    name: "Deleted",
    value: -1,
  },
];
export const KnowledgeList = () => {
  const navigate = useNavigate();
  const user = useStoreState((store) => store.user);
  const [input, setInput] = useState({
    tab: 1,
  });

  const { data: knowledges = [] } = useQuery(
    [queries.knowledges],
    () => {
      return getRequest("/knowledge_bases/su");
    },
    {
      cacheTime: 0, // Disable caching
      staleTime: 0, // Disable stale time
    }
  );

  const columns = useMemo(
    () => [
      {
        Header: "Organization",
        accessor: "org_name",
      },
      {
        Header: () => <span className="text-white">Name</span>,
        accessor: "title",
        Cell: ({ row }: { row: any }) => (
          <div>
            <div>{row.original.title}</div>
            <div className="text-sm text-gray-500">
              {format(
                new Date(row.original.updated_at),
                "MMM dd, yyyy hh:mm a"
              )}
            </div>
          </div>
        ),
      },
      {
        Header: () => <span className="text-white">Purpose</span>,
        accessor: "purpose",
      },
    ],
    []
  );

  const handleRowClick = (data: any) => {
    navigate(`/knowledge-bases/update/${data.id}`, {
      state: {
        name: data.agent_name,
      },
    });
  };

  return (
    <div className="w-full h-full p-10 bg-primary text-white">
      <List
        columns={columns}
        data={knowledges}
        activeTab={input["tab"]}
        onRowClick={handleRowClick}
      >
        <div className="space-y-4">
          <h1 className="text-2xl font-bold">Knowledge Base</h1>
          <div className="flex justify-between items-center">
            <ToggleGroup
              innerClass="w-full flex space-x-6"
              outerClass="pb-1 text-sm data-[state=on]:text-perl data-[state=on]:border-b-2 data-[state=on]:border-perl"
              data={tabs}
              defaultValue={input["tab"]}
              onValueChange={(value: number) =>
                setInput({ ...input, ["tab"]: value })
              }
            />
          </div>
        </div>
      </List>
    </div>
  );
};
