import React, { useEffect, useState } from "react";
import { NormalSwitch, ScrollArea, TextInput } from "../../components";
import { TextArea } from "../../components/input/TextArea";
import { TrashIcon } from "@radix-ui/react-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  optimisticOptions,
  putRequest,
  queries,
} from "../../react-query";
import { useStoreState } from "../../store/easy-peasy/hooks";

export const UpdateLocation = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const user = useStoreState((store) => store.user);

  const { data: location = {} } = useQuery(
    [queries.locations, id],
    () => getRequest(`/locations/?location_id=${id}`),
    {
      enabled: !!id,
    }
  );
  // Fetch organization list
  const { data: organizations = [] } = useQuery(
    "organizations",
    () => getRequest(`/organisations/list`),
    {
      enabled: true,
    }
  );

  const [formData, setFormData] = useState<any>({
    name: "",
    phone: "",
    description: "",
    address: "",
    status: true,
  });

  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    description: "",
    address: "",
    status: "",
  });

  const onChangeHandler = (value: string | boolean, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const { mutate: updateLocation } = useMutation(
    putRequest,
    optimisticOptions(queries.locations)
  );

  const submitHandler = (e: any) => {
    e.preventDefault();
    const { name, phone, description, address, status } = formData;
    updateLocation({
      endPoint: `/locations/su?location_id=${id}`,
      payload: {
        name,
        phone,
        description,
        address,
        status: status === true ? 1 : 0,
        org_id: user.org_id,
      },
    });
    navigate(-1);
  };

  useEffect(() => {
    if (location) {
      const { name, phone, description, address, status } = location;
      setFormData({
        ...formData,
        name,
        phone,
        description,
        address,
        status: status === 1 ? true : false,
      });
    }
  }, [location]);  

  // Find the organization name based on org_id from location
  const organizationName = organizations.find(
    (org: { id: string }) => org.id === location.org_id
  )?.name;

  return (
    <div className="w-full h-full bg-primary text-white">
      <form
        onSubmit={submitHandler}
        className="w-full max-w-4xl mx-auto h-full py-4 space-y-4 sm:space-y-8"
      >
        <div className="rounded-lg border border-header-border bg-primary py-2 px-4 w-full flex flex-col">
          {organizationName && (
            <h2 className="text-xl mb-2 text-white">
              Organization : {organizationName} {/* Display organization name */}
            </h2>
          )}

          <div className="flex space-x-6 text-white">
            <span className="text-perl text-lg font-perl">
              Update Location
            </span>
          </div>
        </div>

        <div className="w-full flex flex-col sm:flex-row justify-between space-y-4 sm:space-y-0 sm:space-x-4 text-white">
          <div className="w-full rounded-lg border border-header-border bg-primary p-4">
            <ScrollArea className="h-[calc(100vh-340px)]">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 px-2 sm:px-4">
                <TextInput
                  type="text"
                  placeholder="eg. Service station - San Jose"
                  value={formData.name}
                  onChange={(e) => onChangeHandler(e.target.value, "name")}
                  required
                  theme="white"
                  error={errors.name}
                >
                  <label htmlFor="name">Location Name</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Enter Contact Number"
                  value={formData.phone}
                  onChange={(e) => onChangeHandler(e.target.value, "phone")}
                  required
                  theme="white"
                  error={errors.phone}
                >
                  <label htmlFor="Phone">Contact Number</label>
                </TextInput>

                <div className="col-span-1 sm:col-span-2">
                  <TextInput
                    type="text"
                    placeholder="eg. 91, Tring Lane, San Fransisco, CA 94100"
                    value={formData.address}
                    onChange={(e) => onChangeHandler(e.target.value, "address")}
                    required
                    theme="white"
                    error={errors.address}
                  >
                    <label htmlFor="Phone">Address</label>
                  </TextInput>
                </div>

                <div className="col-span-1 sm:col-span-2">
                  <TextArea
                    className="focus:outline-none min-h-[25vh] w-full bg-transparent border border-header-border rounded-lg p-2"
                    placeholder="Enter Location note (for internal use only)"
                    value={formData.description}
                    onChange={(e) =>
                      onChangeHandler(e.target.value, "description")
                    }
                    required
                    theme="white"
                    error={errors.description}
                  >
                    <label htmlFor="Phone" className="pt-4">
                      Note
                    </label>
                  </TextArea>
                </div>
              </div>
            </ScrollArea>
            <div className="flex justify-end mt-4">
              {user.access_level > 0 && (
                <button
                  type="submit"
                  className="bg-accent text-primary font-semibold px-6 py-2 rounded-full border-2 border-perl hover:bg-opacity-90 transition-colors duration-200 whitespace-nowrap"
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
