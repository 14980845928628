import React from "react";
import Select, { StylesConfig } from "react-select";

interface DefaultSelectProps {
  data: Array<{ [key: string]: any }>;
  onValueChange: (value: any) => void;
  defaultValue?: any;
  placeholder: string;
  className?: string;
  value?: string;
  theme?: "black" | "white";
  keyField: string;
  valueField: string;
  disabled?: boolean;
  children?: React.ReactNode;
}

export const DefaultSelect: React.FC<DefaultSelectProps> = ({
  data,
  onValueChange,
  defaultValue,
  children,
  placeholder,
  className,
  value,
  theme = "black",
  keyField,
  disabled,
  valueField,
  ...props
}) => {
  const customStyles: StylesConfig<any, true> = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      height: "100%",
      borderRadius: 8,
      background: theme === "black" ? "white" : "transparent",
      color: theme === "black" ? "white" : "black",
      borderColor: "#505050",
      "&:hover": {
        borderColor: "#FFFFF",
      },
      "&:focus": {
        borderColor: "#FFFFF",
      },
      "&:active": {
        borderColor: "#FFFFF",
      },
      boxShadow: "none",
    }),
    menu: (provided) => ({
      ...provided,
      background: theme === "black" ? "transparent" : "#505050",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: theme ? "#fff" : "inherit",  
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#000" : "inherit",
      "&:hover": {
        backgroundColor: state.isFocused ? "#000" : "inherit", 
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: theme === "black" ? "#000000" : "#ffffff",
    }),
  };

  return (
    <div className="space-y-1">
      {children}
      <Select
        isDisabled={disabled}
        className={`w-full inline-flex items-center h-10  rounded-lg   justify-between`}
        styles={customStyles}
        placeholder={placeholder}
        options={data?.map((item) => ({
          label: item[valueField],
          value: item[keyField],
        }))}
        isClearable={false}
        defaultValue={defaultValue}
        value={defaultValue}
        onChange={onValueChange}
        {...props}
      />
    </div>
  );
};
