import React, { useEffect, useState } from "react";
import {
  NormalSwitch,
  CustomSelect,
  DefaultSelect,
  TextInput,
  ScrollArea,
} from "../../components";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  postRequest,
  optimisticOptions,
  queries,
} from "../../react-query";
import { useStoreState } from "../../store/easy-peasy/hooks";


export const CreateUserInvitation = () => {
  const navigate = useNavigate();
  const user = useStoreState((store) => store.user);

  const [formData, setFormData] = useState<{
    name: string;
    email: string;
    phone: string;
    status: boolean;
    org_id: string;
    role: { label: string; value: number };
  }>({
    name: "",
    email: "",
    phone: "",
    status: true,
    org_id: user.org_id,
    role: { label: "Agent", value: 0 },
  });

  const [errors, setErrors] = useState<{
    name: string;
    email: string;
    phone: string;
    status: string;

    org_id: string;
    role: string;
  }>({
    name: "",
    email: "",
    phone: "",
    status: "",

    org_id: "",
    role: "",
  });

  // Define parameter types
  const onChangeHandler = (value: any, name: string): void => {
    setFormData({ ...formData, [name]: value });
  };

  const { mutate: createUser } = useMutation(
    postRequest,
    optimisticOptions(queries.users)
  );

  const submitHandler = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const { name, email, phone, org_id, role } = formData;

    createUser({
      endPoint: `/users/`,
      payload: {
        name,
        email,
        phone,
        status: formData.status === true ? 1 : 0,
        org_id: user.org_id,
        access_level: role.value,
      },
    });

    navigate(-1);
  };

  return (
    <div className="w-full h-full p-4">
      <form
        onSubmit={submitHandler}
        className="w-[65%] mx-auto h-full py-4 space-y-8"
      >
        <div className="rounded-lg border-header-border bg-primary py-2 px-4 w-full flex justify-between items-center">
          <div className="space-y-2 lg:min-w-[500px]">
            <span className="text-perl text-base font-perl">New</span>
          </div>
          <div className="flex space-x-6 text-white">
            <NormalSwitch
              title="Status"
              checked={formData.status}
              onCheckedChange={(value: boolean) =>
                onChangeHandler(value, "status")
              }
            />
          </div>
        </div>
        <div className="w-full flex justify-between space-x-4 text-white">
          <div className="w-full rounded-lg border border-header-border bg-primary p-4">
            <ScrollArea className="h-[calc(100vh-310px)]">
              <div className="space-y-2 grid grid-cols-1 gap-x-5 gap-y-4 px-4">
                <div className="mt-2">
                  <TextInput
                    type="text"
                    placeholder="Enter name"
                    value={formData.name}
                    onChange={(e) => onChangeHandler(e.target.value, "name")}
                    required
                    theme="white"
                    error={errors.name}
                  >
                    <label htmlFor="name">Name</label>
                  </TextInput>
                </div>
                <TextInput
                  type="email"
                  placeholder="Enter email"
                  value={formData.email}
                  onChange={(e) => onChangeHandler(e.target.value, "email")}
                  required
                  theme="white"
                  error={errors.email}
                >
                  <label htmlFor="email">Email</label>
                </TextInput>
                <div className="flex items-center space-x-2">
                  <TextInput
                    type="tel"
                    placeholder="Enter phone number"
                    value={formData.phone}
                    onChange={(e) => onChangeHandler(e.target.value, "phone")}
                    required
                    theme="white"
                    error={errors.phone}
                  >
                    <label htmlFor="phone">Phone</label>
                  </TextInput>
                </div>
                <DefaultSelect
                  theme="white"
                  data={[
                    { label: "Agent", value: 0 },
                    { label: "Admin", value: 1 },
                  ]}
                  value={String(formData.role.value)}
                  onValueChange={(value) => onChangeHandler(value, "role")}
                  placeholder="Select"
                  keyField="value"
                  valueField="label"
                >
                  <label htmlFor="role">Role</label>
                </DefaultSelect>
              </div>
            </ScrollArea>
            <div className="flex justify-end">
              <button type="submit" className="px-4 py-1.5 bg-green rounded-lg">
                Invite
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
